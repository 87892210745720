.register-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid red; */
}

.register-inner-card {
  width: 80%;
  height: 85%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
}

.register-inner-first-card {
  width: 50%;
  height: 100%;
  background-color: #f5f5f5;
}

.register-inner-first-card > img {
  width: 100%;
}

.register-inner-second-card {
  width: 50%;
  padding: 30px;
  height: 100%;
  /* border: 2px solid red; */
}
