.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: 1.3rem;
  /* border: 2px solid yellow; */
  padding: 40px 60px;
}

.login-container > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}

.login-container > div > label {
  color: grey;
  font-size: 14px;
}

.login-inner-input-container {
  /* display: flex; */
  height: 45px;
  display: flex;
  width: 100%;
  gap: 0.5rem;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  padding: 0px 5px;
  border: 1px solid lightgrey;
}

.login-inner-input-container > input {
  width: 95%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 16px;
}

.login-container > button {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  font-size: 17px;
  font-weight: 600;
  background-color: #ff6600;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}

.login-container > p {
  font-size: 16px;
  color: grey;
}

.login-container > p > span {
  color: blue;
  cursor: pointer;
}

.error-message {
  color: red;
}
