.exam-main-list-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.exam-main-inner-card {
  width: 100%;
  display: flex;
  flex-direction: column;

  height: calc(100% - 90px);
  padding: 20px;
  gap: 1rem;
}

.exam-main-inner-container {
  width: 90%;
  height: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
