.addp-exam-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* border: 2px solid yellow; */
}
.add-exam-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  /* background-color: #f2f2f2; */
  height: calc(100% - 90px);
  padding: 30px;
  gap: 1rem;

  /* border: 2px solid red; */
}

.test-id-card {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.test-id-inner-crad {
  width: 200px;
  height: 45px;
  border-radius: 10px;
  overflow: hidden;
  /* border: 1px solid red; */
  display: flex;
}
.test-id-inner-crad > :nth-child(1) {
  width: 40%;
  height: 100%;
  background-color: #ff6600;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}
.test-id-inner-crad > :nth-child(2) {
  width: 60%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.37);
}

.add-exam-all-form-card {
  width: 100%;
  height: 95%;
  /* border: 2px solid red; */
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.add-exam-first-form-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.add-exam-first-form-inner-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.add-exam-first-form-inner-input-card {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  width: 340px;
  /* border: 2px solid red; */
}

.add-exam-first-form-inner-input-card > select {
  width: 100%;
  height: 40px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 10px;
  outline: none;
}

.add-exam-first-form-inner-input-card > input {
  width: 100%;
  height: 40px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 10px;
  outline: none;
}

.add-exam-upload-btn-card {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 2rem;
}

.add-exam-upload-btn-card > button {
  width: 150px;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
}

.add-exam-upload-btn-card > :nth-child(1) {
  background-color: #ff6600;
  color: #fff;
  border: none;
  outline: none;
}

.add-exam-upload-btn-card > :nth-child(2) {
  background-color: transparent;
  color: #ff6600;
  border: 2px solid #ff6600;
  /* outline: nonxe; */
}

.add-exam-description-card {
  width: 100%;
  height: 80px;
}

.add-exam-description-card > textarea {
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  border: 1px solid lightgrey;
  outline: none;
}

.add-exam-each-section-btn-card {
  display: flex;
  width: 100%;
  gap: 2rem;
}

.add-exam-each-section-btn-card > button {
  padding: 4px 10px;
  width: fit-content;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
}
